// import img2 from "../../Images/logo.png";
// import R_Logo from "./Images/R_Logo.png";
import { Link } from "react-router-dom";
import "../../Styles/App.css";
import "../../components/customer/Customer.css";
import DashboardHeader from "../Dashboard/DashboardHeader";

export default function Header() {
  return (
    <>
      <div className="product_header">
        <DashboardHeader />
      </div>

      <div className="lists">
        <Link
          to="/CompanyList"
          style={{ textDecoration: "none", marginLeft: "30px" }}
        >
          <li>Company List</li>
        </Link>

        <Link
          to="/ProductList"
          style={{ textDecoration: "none" }}
        >
          <li>Product List</li>
        </Link>

        <Link to="/New_Product" style={{ textDecoration: "none" }}>
          <li>New Products</li>
        </Link>
        {/* <Link to="/All_P" style={{ textDecoration: "none" }}><li>Products List</li></Link> */}
      </div>
    </>
  );
}
