import { useRef, useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import CustomerFooter from "./CustomerFooter";
import HeaderCustomer from "../../snippets/Customer/HeaderCustomer";
import './Customer.css';
import ApiHandler from "../../ApiHandler";
import GlobalVariable from "../../GlobalVariable";
import TransferData from "../../TransferData";
import * as XLSX from 'xlsx';
import { addDays } from 'date-fns';
import { DatePicker } from "antd";
import dayjs from "dayjs";
import LoaderTri from "../Loader/LoaderTri";



const initialValues = {
    from: dayjs(),
    to: dayjs()
};
export default function OrderList() {
    const [dates, setDates] = useState(initialValues);

    const [showOrderList, setShowOrderList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [alldatesData, setAlldatesData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [openDate, setOpenDate] = useState(false)
    const [viewdata, setViewdata] = useState(false)
    const [range, setRange] = useState([{
        startdate: new Date(),
        endDate: addDays(new Date(), ''),
        key: 'selection'
    }])
    function toGetData() {
        setViewdata(true)
        setShowOrderList([])
        setIsLoading(true)
        const user = JSON.parse(localStorage.getItem('user-info'))
        const localUrl = JSON.parse(localStorage.getItem('LocalURL'));
        ApiHandler.post({

            //url: localUrl + "api/OwnApp/GetComOrderList",
            url: "api/OwnAppReports/CustomerOrders",
            data:

            {
                wClientid: GlobalVariable.wcClient.toString(),
                custCode: user.code,
                dtFrom: dayjs(dates.from).format("YYYYMMDD"),
                dtTo: dayjs(dates.to).format("YYYYMMDD"),
                // dtFrom: moment(selectionRange.startDate).format("YYYYMMDD"),
                // dtTo: moment(selectionRange.endDate).format("YYYYMMDD"),
            }

        })
            .then(((result) => {
                console.log(result);
                console.log(result.data);
                setShowOrderList(result.data)
                // console.log(result.data.orderdate)
            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }

            })
            .finally(() => {
                setIsLoading(false)
            })


    }
    const refOne = useRef(null);

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true)
            document.removeEventListener("click", hideOnClickOutside, true)
        }
    }, []);

    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpenDate(false)
        }
    }


    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpenDate(false)
        }
    }

    const handleSelects = (date) => {

        let filtered = alldatesData.filter((product) => {

            let productDate = new Date(product["date"]);
            return (
                productDate >= date.selection.startDate &&
                productDate <= date.selection.endDate
            );
        });
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
        setShowOrderList(filtered);
        console.log(date.selection.startDate)
        console.log(date.selection.endDate)
    };

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
    };
    const ToremoveElm = (elm) => {
        console.log(elm);
        let string = elm.orderdate + '';
        console.log(string.substring(0, 10));
        return string.substring(0, 10)
    }
    function handleExportDataExcel() {
        console.log(showOrderList);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(showOrderList);
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
        XLSX.writeFile(wb, "Reports.xlsx");
    }


    return (<>
        <HeaderCustomer />
        <div style={{ minHeight: window.innerHeight / 1.4 }}>
            <div className="row m-0">
                <div className="col-lg-10 offset-lg-1">

                    <h5 style={{
                        marginTop: "20px",
                        // marginLeft: "20px",
                        fontFamily: "sans-serif",
                        // fontSize: "17px",
                        // padding: "10px"

                    }}>Select details to view Order List</h5>
                </div>

                <div className="col-lg-10 offset-lg-1">
                    <div className="row  ">
                        <div className="col-lg-3  ">
                            <DatePicker.RangePicker
                                size="middle"
                                className="mb-2"
                                value={[dates.from, dates.to]}
                                format="DD-MM-YYYY"
                                onChange={(e) => {
                                    console.log(e);

                                    if (e) {
                                        setDates({
                                            from: e[0],
                                            to: e[1],
                                        });
                                    } else {
                                        setDates({ from: null, to: null });
                                    }
                                }}
                            />
                        </div>
                        <div className="col-lg-4  "   >
                            <button
                                type="button"
                                className="btn mb-3 btn-primary btn-sm"
                                onClick={toGetData}

                            >View</button>
                        </div>
                    </div>
                </div>


                {/* <div className="enterDateInput">


                <input
                    value={`${moment(selectionRange.startDate).format("DD-MM-YYYY")} to ${moment(selectionRange.endDate).format("DD-MM-YYYY")}`}
                    // value={`${format(range[0].startdate, 'MM-dd-yyyy')}  to  ${format(range[0].endDate, 'MM-dd-yyyy')} `}
                    readOnly
                    onClick={() => setOpenDate(true)}
                    style={{
                        width: "300px",
                        paddingLeft: '30px',



                    }}
                    required

                />
                <button

                    type="button"
                    onClick={toGetData}

                >View</button>

            </div>

            <div className="dateRangePicker" ref={refOne}>



                {

                    openDate &&
                    <DateRange
                        onChange={handleSelects}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        months={2}
                        direction="horizontal"
                        showMonthAndYearPickers={false}
                        className="DaterangePicker"

                    />


                }
            </div> */}

                {
                    viewdata &&
                    <div className="p-0 col-lg-10 offset-lg-1">

                        <div className="billTarckData">
                            <h4>Order List</h4>
                            {showOrderList.length > 0 &&
                                <button onClick={handleExportDataExcel} >Download</button>}
                            <div style={{
                                width: "100%",
                                overflowX: "scroll"
                            }}>
                                <Table striped >
                                    <thead className="threAd">
                                        <tr className="threAd_tr">
                                            <th>{TransferData.orderDate}</th>
                                            <th>{TransferData.orderNo}</th>
                                            <th
                                                style={{
                                                    minWidth: "200px"
                                                }}
                                            >{TransferData.product_Name}</th>
                                            <th>{TransferData.pack}</th>
                                            <th>{TransferData.status}</th>

                                        </tr>
                                    </thead>

                                    {


                                        showOrderList.map((elm, index) => {
                                            return <tbody className="tboDY" key={index} >
                                                <tr >
                                                    <td >{ToremoveElm(elm)}</td>
                                                    <td>{elm.orderno}</td>
                                                    <td>{elm.prodName}</td>
                                                    <td>{elm.pack}</td>
                                                    <td>{elm.repName}</td>

                                                </tr>


                                            </tbody>



                                        })

                                    }

                                </Table >
                            </div>
                            {isLoading ?
                                <div className="d-flex justify-content-center" style={{

                                }}>

                                    <LoaderTri />

                                </div>


                                : null
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
        <CustomerFooter />
    </>)
}