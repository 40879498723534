const Company = [
  {
    name: "GREEN MEDICO LLP",
    fullName: "GREEN MEDICO LLP",
    tagline: "Pharmaceutical Distributor",
    phone: "Ph.: 0230 - 2431008",
    alternateNumber: ["Mob: 9922437680", "9552807946"],
    email: "Email: greenmedicollp@gmail.com",
    website: "Website: www.greenmedicollp.com",
    address: "20/75 S NO-24885,<br/>Near Meghdut Hotel,<br/>Ichalkaranji",
  },
];

const BankDetails = [
  {
    label: "Bank Details",
    value: "HDFC Bank",
  },
  {
    label: "Account Name",
    value: "GREEN MEDICO LLP",
  },
  {
    label: "A/C. No.",
    value: "50200024903249",
  },
  {
    label: "IFSC Code:",
    value: "HDFC0000736",
  },
  {
    label: "GSTIN",
    value: "27AA0FG9471F1ZX",
  },
];

export { Company, BankDetails };