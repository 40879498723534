import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import CustomerFooter from "../../snippets/Customer/customFooter";
import "./Customer.css";
// import CustomerFooter from "./CustomerFooter";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import Typeahead from "../Typeahead";
import { useDispatch, useSelector } from "react-redux";
import GlobalVariable from "../../GlobalVariable";
import ApiHandler from "../../ApiHandler";
import { userOrderAction } from "./Userproducts/UserOrders";
import useGlobalEvent from "beautiful-react-hooks/useGlobalEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { Image, Carousel } from "react-bootstrap";
// import {  } from "antd";
import { Result } from "antd";
import LoaderTri from "../Loader/LoaderTri";
import DashboardHeader from "../../snippets/Dashboard/DashboardHeader";
import HeaderCustomer from "../../snippets/Customer/HeaderCustomer";

const productsColumns = [
  {
    header: "Product Name",
    field: "prodName",
    cols: 3,
  },
  {
    header: "Company",
    field: "companyName",
    cols: 2,
  },
  {
    header: "Pack",
    field: "case1",
    cols: 2,
  },
  {
    header: "Scheme",
    field: "scheme",
    cols: 2,
  },
  {
    header: "Stock",
    field: "stock",
    cols: 1,
  },
  {
    header: "MRP",
    field: "mrp",
    cols: 1,
  },

  {
    header: "Rate",
    field: "rate",
    cols: 1,
  },
  // {
  //     header: "Gst",
  //     field: "pGST",
  //     cols: 1,
  // },
];

const indialOrder = {
  // wClientid: GlobalVariable.W2,
  wClientid: GlobalVariable.wcClient,
  CustCode: 0,
  repcode: 0,
  // uniqueOrderID: GlobalVariable.W2,
  uniqueOrderID: GlobalVariable.wcClient,
  orderType: "web",
  rclientid: null,
  urgent: 0,
  remarks: "",
  mobNo: "",
  orderItems: [],
};

export const selectedProductNameLocalStorage = "selcted-Products";

let LoadCountForSetOrderItem = 0;

export default function CustomerDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const grandTotalvalues = useSelector(
    (state) => state.userOrders.increaseprice
  );
  const [smShow, setSmShow] = useState(false);
  const [newProduct, setNewProduct] = useState([]);
  const [compName, setCompName] = useState();
  const [selectedProductName, setselectedProductName] = useState();
  const [selectedcase1, setselectedcase1] = useState();
  const [selectedScheme, setselectedScheme] = useState(0);
  const [selectedStock, setselectedStock] = useState();
  const [selectedPrice, setselectedPrice] = useState();
  const [rates, setRates] = useState();
  const [increasePrice, setIncreasePrice] = useState(0);
  const [hideDateTime, sethideDateTime] = useState(false);
  const [data, setData] = useState(0);
  const [visible, setVisible] = useState(false);
  const [noOfquantity, setNoOfQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [remarksMessage, setRemarksMessage] = useState("--Select--");
  const [input, setInput] = useState("");
  const [productCode, setProductCode] = useState("");
  const [zIndex, setZIndex] = useState("1");

  const qtyInputRef = useRef();

  const userOrders = useSelector((state) => state.userOrders);

  let selectedProductsLocalStorage = useMemo(() => {
    let temp = JSON.parse(
      localStorage.getItem(selectedProductNameLocalStorage)
    );
    temp = temp ? temp : [];
    return temp;
  }, []);

  const [order, setOrder] = useState({
    ...indialOrder,
    orderItems: [...selectedProductsLocalStorage],
    remarks: userOrders.remarksMessageBYcomp,
  });

  const [showStock, setShowStock] = useState();
  const [localURL, setLocalURL] = useState("");
  const [showRemarks, setshowRemarks] = useState(false);
  const [sumValue, setsumValue] = useState(grandTotalvalues);
  const [prodGST, setProdGST] = useState(0);
  const [orderclosed, setorderclosed] = useState();
  const [sildeimgURL1, setsildeimgURL1] = useState();
  const [sildeimgURL2, setsildeimgURL2] = useState();
  const [sildeimgURL3, setsildeimgURL3] = useState();
  const [sildeimgURL4, setsildeimgURL4] = useState();
  const [sildeimgURL5, setsildeimgURL5] = useState();
  const [show, setShow] = useState(false);
  const [submitOrderNo, setsubmitOrderNo] = useState();
  const [orderSuccess, setorderSuccess] = useState();
  const [handleOpen, sethandleOpen] = useState(false);
  const [dataSTATUS, setdataSTATUS] = useState();
  let pickuporder = "PickUp Order";
  let deliveryOrder = "Delivery Order";

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useGlobalEvent("resize");
  onWindowResize(() => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  });

  const searchProduct = useSelector((state) => state.userOrders.searchProduct);
  const customerCompMessage = useSelector(
    (state) => state.userOrders.remarksMessageBYcomp
  );

  useEffect(() => {
    // console.log("userOrderss", userOrders);
  }, [userOrders, searchProduct]);

  // after click, it will be false
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true)
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, [grandTotalvalues]);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setshowRemarks(false);
    }
  };

  useEffect(() => {
    const cancelTokenSource = ApiHandler.cancelToken.source();
    const user = JSON.parse(localStorage.getItem("user-info"));
    ApiHandler.get({
      url: "/api/OwnAppConfigs/" + GlobalVariable.wcClient.toString(),
      cancelToken: cancelTokenSource.token,
    })
      .then((res) => {
        //    console.log(res)
        //  console.log(res.showStocktoCust)
        setShowStock(res.showStocktoCust);
        // setLocalURL(res.localUrl);
        window.localStorage.setItem("LocalURL", JSON.stringify(res.localUrl));

        setorderclosed(res.orderClosedCust);
        setsildeimgURL1(res.bannerUrl1);
        setsildeimgURL2(res.banerUrl2);
        setsildeimgURL3(res.banerUrl3);
        setsildeimgURL4(res.banerUrl4);
        setsildeimgURL5(res.banerUrl5);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelTokenSource.cancel("Component unmounted or request canceled.");
    };
  }, []);

  useEffect(() => {
    setOrder((v) => {
      const user = JSON.parse(localStorage.getItem("user-info"));
      const date = new Date();
      return {
        //   ...v, uniqueOrderID: GlobalVariable.W2 + "_" + user.code + "_" +
        ...v,
        uniqueOrderID:
          GlobalVariable.wcClient +
          "_" +
          user.code +
          "_" +
          date.getDay() +
          date.getMonth() +
          date.getFullYear() +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds() +
          date.getMilliseconds() * 100000,

        //   ...v, uniqueOrderID: wcClient + "_" + user.code + "_" + new Date()
      };
    });
  }, []);

  const token = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user-info"));
    user &&
      setOrder((v) => {
        return { ...v, CustCode: user.code };
      });
  }, []);

  const user = useMemo(() => JSON.parse(localStorage.getItem("user-info")), []);

  function SearchHandler(text) {
    setLoading(true);
    ApiHandler.post({
      url: "/api/Search/ProductByName/",
      data: {
        wClientid: GlobalVariable.wcClient + "",
        search: text,
        areaCode: user.areaCode + "",
      },
    })
      .then((res) => {
        // console.log(res.data);
        const mappedData = res.data.map((data) => {
          return {
            // ...data, stock: showStock ? "YES (" + data.stock + ")" : "No",
            //  ...data, stock: showStock === true && data.stock > 0 ? "YES" : "NO",
            // ...data, stock: showStock === true ? data.stock + ' ' : data.stock > 0 ? "YES" : "NO",
            ...data,
            stock:
              showStock === true
                ? data.stock && data.stock > 500
                  ? "500+"
                  : data.stock + " "
                : data.stock > 0
                ? "YES"
                : "NO",
          };
        });
        setNewProduct(mappedData);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
        setNewProduct([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    let total = 0;

    order.orderItems &&
      Array.isArray(order.orderItems) &&
      order.orderItems.forEach((data) => {
        const amt =
          (data.qty ? parseFloat(data.qty) : 0) *
          (data.rte ? parseFloat(data.rte) : 0);
        const taxableAmt =
          (amt * (data.pGst ? parseFloat(data.pGst) : 0)) / 100;
        total += amt + taxableAmt;
      });

    setIncreasePrice(total.toFixed(2));
  }, [order]);

  function addValuehandler(e, i) {
    setSmShow(false);

    let x = JSON.parse(localStorage.getItem(selectedProductNameLocalStorage));
    x = x ? x : [];
    // let p = x.concat(temp.orderItems)
    // console.log(p);
    let exists = false;
    x.forEach((element) => {
      if (element.productCode === productCode) {
        element.qty = noOfquantity ? noOfquantity : 1;
        exists = true;
      }
    });

    if (exists === false) {
      const temp = {
        ...order,
        orderItems: [
          ...x,
          {
            slno: data + 1,
            productCode: productCode,
            comp1: compName,
            prd1: selectedProductName,
            cs1: selectedcase1,
            sc1: selectedScheme,
            mrp1: selectedPrice,
            qty: noOfquantity ? noOfquantity : 1,
            rte: rates,
            pGst: prodGST,
            showTime: new Date(),
          },
        ],
      };
      setOrder(temp);
      dispatch(userOrderAction.setProductData(temp.orderItems));
      console.log(temp.orderItems);
      localStorage.setItem(
        selectedProductNameLocalStorage,
        JSON.stringify(temp.orderItems)
      );
      setData(data + 1);
    } else {
      setOrder((v) => {
        return {
          ...v,
          orderItems: x,
        };
      });
      dispatch(userOrderAction.setProductData(x));
      console.log(x);
      localStorage.setItem(selectedProductNameLocalStorage, JSON.stringify(x));
    }
  }

  // useEffect(() => {
  //     console.log("orderitems ", order.orderItems)
  // }, [order.orderItems])

  function onEnterAddValuehandler(e, i) {
    if (e.key === "Enter") {
      setSmShow(false);

      //console.log(grandTotalvalue += increasePrice * e.target.value)
      //const date = new Date();

      let x = JSON.parse(localStorage.getItem(selectedProductNameLocalStorage));
      x = x ? x : [];
      // let p = x.concat(temp.orderItems)
      // console.log(p);
      let exists = false;
      x.forEach((element) => {
        if (element.productCode === productCode) {
          element.qty = noOfquantity ? noOfquantity : 1;
          exists = true;
        }
      });

      if (exists === false) {
        const temp = {
          ...order,
          orderItems: [
            ...x,
            {
              slno: data + 1,
              productCode: productCode,
              comp1: compName,
              prd1: selectedProductName,
              cs1: selectedcase1,
              sc1: selectedScheme,
              mrp1: selectedPrice,
              qty: noOfquantity ? noOfquantity : 1,
              rte: rates,
              pGst: prodGST,
              showTime: new Date(),
            },
          ],
        };
        setOrder(temp);
        dispatch(userOrderAction.setProductData(temp.orderItems));
        console.log(temp.orderItems);
        localStorage.setItem(
          selectedProductNameLocalStorage,
          JSON.stringify(temp.orderItems)
        );
        setData(data + 1);
      } else {
        setOrder((v) => {
          return {
            ...v,
            orderItems: x,
          };
        });
        dispatch(userOrderAction.setProductData(x));
        console.log(x);
        localStorage.setItem(
          selectedProductNameLocalStorage,
          JSON.stringify(x)
        );
      }
    }
  }

  function DeleteData(i, productCode) {
    dispatch(userOrderAction.removeItem({ i, productCode }));
    setVisible(!visible);
    setOrder((v) => {
      v.orderItems = v.orderItems.filter((c) => c.productCode !== productCode);
      return { ...v };
    });
  }

  const [productSeach, setProductSeach] = useState({
    options: [],
    selected: [],
    search: "",
  });

  const [errorMessage, setErrorMessage] = useState("Could Not save");

  function submitDatahandler(e) {
    setIsLoading(true);
    e.preventDefault();

    let requestData = {
      ...order,
      orderItems: order.orderItems.map((e) => {
        return {
          wProdCode: e.productCode,
          qty: e.qty,
          free: 0,
          ProductName: e.prd1 || e.prodName,
          price: e.mrp1 || e.mrp,
          entryTime: e.showTime,
          rProdCode: "",
          packs: e.cs1 || e.case1,
          wBarcode: "",
          sourceId: 0,
        };
      }),
      remarks:
        remarksMessage === "--Select--" ? customerCompMessage : remarksMessage,
    };

    ApiHandler.post({
      url: "api/Orders/WritePopsOrders/",
      data: requestData,
    })
      .then((res) => {
        const user = JSON.parse(localStorage.getItem("user-info"));
        const date = new Date();

        setOrder({
          ...indialOrder,
          CustCode: user.code,
          orderItems: [],
          remarks: "",
          uniqueOrderID:
            GlobalVariable.wcClient +
            "_" +
            user.code +
            "_" +
            date.getDay() +
            date.getMonth() +
            date.getFullYear() +
            date.getHours() +
            date.getMinutes() +
            date.getSeconds() +
            date.getMilliseconds() * 100000,
        });

        console.log(res);
        console.log(res.headers.status);
        setdataSTATUS(res.headers.status);
        setorderSuccess(res.data.status);
        setsubmitOrderNo(res.data.orderNo);

        console.log(requestData);
        setData(requestData.SrNo + 1);
        localStorage.setItem(
          selectedProductNameLocalStorage,
          JSON.stringify([])
        );
      })
      .catch((err) => {
        console.error(err);

        err.message && setErrorMessage(err.message);
        setorderSuccess("Failed");
      })
      .finally(() => {
        setShow(true);

        setIsLoading(false);
      });
  }

  // var searchProductData = []
  // useEffect(() => {
  // var searchProductData = JSON.parse(localStorage.getItem(selectedProductNameLocalStorage));
  // console.log(searchProductData)
  // }, [])

  // useEffect(() => {
  //     const saveToAllData = { searchProduct }
  //     console.log(saveToAllData);
  //     window.localStorage.setItem("selcted-Products-orderbyComp", JSON.stringify(saveToAllData));
  // });

  var obj = {
    img1: sildeimgURL1,
    img2: sildeimgURL2,
    img3: sildeimgURL3,
    img4: sildeimgURL4,
    img5: sildeimgURL5,
  };

  var imges = [];

  if (obj.img1) {
    imges.push(obj.img1);
  }

  if (obj.img2) {
    imges.push(obj.img2);
  }

  if (obj.img3) {
    imges.push(obj.img3);
  }

  if (obj.img4) {
    imges.push(obj.img4);
  }
  if (obj.img5) {
    imges.push(obj.img5);
  }

  console.log({ zIndex });
  return (
    <>
      {/* <DashboardHeader /> */}
      <HeaderCustomer />

      <div className="CustomerSubmitSection">
        <div style={{ minHeight: window.innerHeight / 1.31 }}>
          <div className="placeOrder">
            {orderclosed === true ? (
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: "700",
                  fontFamily: "Courier",
                  color: "red",
                }}
              >
                {" "}
                Order Closed
              </h1>
            ) : (
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: "700",
                  fontFamily: "Courier",
                }}
              >
                {" "}
                Place Order
              </h1>
            )}
          </div>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                width: "100%",
                height: "100%",
                // right: -1,
                backgroundColor: "#ffffff91",
                zIndex: 999,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  height: "100%",
                }}
              >
                <LoaderTri height="120" width="120" />
              </div>
            </div>
          )}

          <br />

          <div id="searchbar row  ">
            <div className="col-12 col-lg-12 col-xl-8 offset-xl-2 px-2">
              <Typeahead
                stock={selectedStock}
                //showStock={showStock}
                // style={{ width: windowWidth < 500 ? "25%" : "1200px", zIndex: 0 }}
                style={{ width: "100%", zIndex: 0 }}
                placeholder="Search Product"
                id="searchProduct"
                //className="w-75"
                className="searchProduct"
                labelKey="prodName"
                name="prodName"
                columns={productsColumns}
                colWidth={window.screen.width}
                isLoading={false}
                onFocus={() => {
                  console.log("focus");
                  setZIndex("-1");
                }}
                onBlur={() => {
                  setZIndex("1");
                  console.log("blur");
                }}
                //when typing in the text box search in handled here
                onSearch={(e) => {
                  // console.log(e);
                  setProductSeach({ ...productSeach, search: e });
                  SearchHandler(e);
                }}
                // when value is choosen or selected
                onChange={(e) => {
                  console.log(e[0]);
                  //  setProductSeach({ ...productSeach, selected: e });
                  const elm = e[0];
                  setSmShow(true);
                  setVisible(false);
                  setCompName(elm.companyName);
                  setselectedProductName(elm.prodName);
                  setselectedcase1(elm.case1);
                  setselectedScheme(elm.scheme);
                  setselectedStock(elm.stock);
                  setselectedPrice(elm.mrp);
                  setRates(elm.rate);
                  setProductCode(elm.prodCode);
                  setProdGST(elm.gst);
                }}
                options={newProduct ? newProduct : []}
                selected={productSeach.selected ? productSeach.selected : []}
              />
            </div>
          </div>

          <div id="  row ">
            <div className="p-2 col-lg-12 col-xl-8 offset-xl-2">
              <div
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <Table striped bordered variant=" ">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#00aff0",
                        color: "white",
                        border: "1px solid black",
                      }}
                    >
                      <th>#</th>
                      {hideDateTime ? <th>Time</th> : null}

                      <th>Product Name</th>
                      <th>Packing</th>
                      <th>Scheme</th>
                      <th>MRP</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      {hideDateTime ? <th>Gst</th> : null}
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      // order.orderItems && Array.isArray(order.orderItems) && order.orderItems.map((data, i) => {
                      order.orderItems.map((data, i) => {
                        //searchProductData && Array.isArray(searchProductData) && searchProductData.map((data, i) => {
                        const indexFix = i;
                        return (
                          <tr key={i} style={{ border: "1px solid black" }}>
                            {/* <td>{data.slno === i || i + 1}</td> */}

                            {/* <td>{data.slno !== i ? i + 1 : ++i}</td> */}
                            {/* <td>{data.slno || ++i}</td> */}

                            {hideDateTime ? <td> {data.showTime}</td> : null}

                            <td>{indexFix + 1}</td>
                            <td>{data.prd1 || data.prodName}</td>
                            <td>{data.cs1 || data.case1}</td>
                            <td>{data.sc1 || data.scheme}</td>
                            <td>{data.mrp1 || data.mrp}</td>
                            <td
                              style={{
                                maxWidth: "100px",
                                padding: 0,
                              }}
                            >
                              <input
                                type="number"
                                min={0}
                                value={data.qty ? data.qty : ""}
                                onChange={(e) => {
                                  // if (order.orderItems && Array.isArray(order.orderItems) && order.orderItems[i]) {
                                  //if (searchProduct && Array.isArray(searchProduct) && searchProduct[i]) {

                                  setOrder((prev) => {
                                    let res = prev;
                                    res.orderItems = prev.orderItems.map(
                                      (elm, indexChange) => {
                                        if (
                                          elm.productCode === data.productCode
                                        ) {
                                          console.log({
                                            "OnchNge value ": e.target.value,
                                          });
                                          return {
                                            ...elm,
                                            qty: e.target.value,
                                          };
                                        }
                                        return { ...elm };
                                      }
                                    );
                                    localStorage.setItem(
                                      selectedProductNameLocalStorage,
                                      JSON.stringify(res.orderItems)
                                    );
                                    console.log("prev ", prev.orderItems[0]);

                                    return { ...prev };
                                  });
                                  // }

                                  // dispatch(userOrderAction.setQty({ index: indexFix, value: e.target.value }))
                                  //  dispatch(userOrderAction.setQtys({ index: i, value: e.target.value }))
                                }}
                                className="form-control"
                                style={
                                  {
                                    // width: "100%",
                                    // maxWidth: 200
                                  }
                                }
                              />
                            </td>
                            <td>{data.rte || data.rate}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                DeleteData(i, data.productCode);
                              }}
                            >
                              Delete{" "}
                              <AiOutlineDelete
                                style={{ marginLeft: "7px", color: "red" }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <Modal
            size="lg"
            style={{ width: "95%" }}
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            onEntered={() => {
              qtyInputRef.current && qtyInputRef.current.focus();
              // input && input.focus()
            }}
          >
            <Modal.Header closeButton style={{ backgroundColor: "#2EC8A6" }}>
              <Modal.Title>
                <span style={{ color: "white", fontWeight: "300" }}>
                  {selectedProductName}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <div
                  className="row"
                  style={{
                    borderBottom: "1px solid #d9d9d9",
                    padding: "9px",
                    color: "#737373",
                    fontWeight: "700",
                  }}
                >
                  <div className="col-xl-8 ">{compName}</div>
                  <div className="col-xl-4">Scheme : {selectedScheme}</div>
                  <div className="col-xl-4">
                    <div>Packing : {selectedcase1}</div>
                    <div>Case : -,-</div>
                  </div>
                  <div className="col-xl-4">
                    <div>Rate : {rates}</div>
                    <div>Min : 0</div>
                  </div>
                  <div className="col-xl-4">
                    <div>Stock : {selectedStock}</div>
                    <div>MRP : {selectedPrice}</div>
                  </div>

                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-8">
                      </div>

                      <div className="col-xl-4">
                        <div className="row">
                          <div className="col-xl-3">
                            Qty :
                          </div>

                          <div className="col-xl-9">
                            <input
                              min={0}
                              // ref={(input) => {
                              //     setTimeout(() => {

                              //         // input && input.focus()
                              //     },);
                              // }}
                              ref={qtyInputRef}
                              type="number"
                              // style={{ width: "100px" }}
                              className="form-control form-control-sm"
                              onChange={(e) => setNoOfQuantity(e.target.value)}
                              onKeyPress={onEnterAddValuehandler}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    
                  </div>
                </div>
              </Container>
            </Modal.Body>
            <Button className="BtnsA" onClick={addValuehandler}>
              Add Product
            </Button>
          </Modal>

          <div className="row m-0">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 offset-xl-2">
              <div className="px-2">
                <h5
                  className="mb-0 mt-2"
                  style={{ fontFamily: "Courier", color: "rgb(89, 91, 91)" }}
                >
                  Priority
                </h5>
                <select className="form-control">
                  <option
                    style={{ fontFamily: "Courier", color: "rgb(89, 91, 91)" }}
                    value="Normal"
                  >
                    Normal
                  </option>
                  <option
                    style={{ fontFamily: "Courier", color: "rgb(89, 91, 91)" }}
                    value="Urgent"
                  >
                    Urgent
                  </option>
                </select>
              </div>
              <div className="px-2">
                <h5
                  className="mb-0 mt-2"
                  onClick={() => setshowRemarks(true)}
                  style={{ fontFamily: "Courier", color: "rgb(89, 91, 91)" }}
                >
                  Remarks
                </h5>

                <div
                  className=" Remarks_list form-control"
                  onClick={() => setshowRemarks(true)}
                >
                  {/* <h6 className="mb-0"> */}
                  {customerCompMessage && remarksMessage
                    ? customerCompMessage
                    : remarksMessage}
                  {/* </h6> */}
                </div>
                {showRemarks && (
                  <div className="selection-remarks-list" ref={refOne}>
                    <li
                      name="PickUpOrder"
                      onClick={() =>
                        setRemarksMessage(pickuporder) + setshowRemarks(false)
                      }
                      value="PickupOrder"
                      style={{ color: "rgb(89, 91, 91)" }}
                    >
                      Pickup Order
                    </li>
                    <li
                      onClick={() =>
                        setRemarksMessage(deliveryOrder) + setshowRemarks(false)
                      }
                      value="Delivery Order"
                      style={{ color: "rgb(89, 91, 91)" }}
                    >
                      Delivery Order
                    </li>
                    <li
                      onClick={() => setInput()}
                      value="Remarks"
                      style={{ fontWeight: "bold" }}
                    >
                      Remarks
                    </li>

                    <textarea
                      name="Text1"
                      cols="68"
                      rows="2"
                      //   value={customerCompMessage}
                      onChange={(e) => setRemarksMessage(e.target.value)}
                    ></textarea>
                  </div>
                )}
              </div>

              <h5
                className="text-center"
                style={{
                  marginTop: "20px",
                  // marginLeft: "200px",
                  fontFamily: "Courier",
                  fontSize: "18px",
                  color: "rgb(89, 91, 91)",
                  fontWeight: "bold",
                }}
              >
                Approx Order Value : Rs.
                {increasePrice}
              </h5>

              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    disabled={
                      orderclosed === false && order.orderItems.length === 0
                        ? true
                        : false
                    }
                    className="SubmitButton"
                    onClick={submitDatahandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
              <div className="  ">
                <Carousel style={{ zIndex: -1 }}>
                  {imges.map((a, index) => (
                    <Carousel.Item key={index}>
                      <Image
                        key={index}
                        //   style={{ zIndex: zIndex }}
                        width="100%"
                        height="300px"
                        // className="d-block w-20"
                        src={a}
                        alt="Adv"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>

          {
            <div className="modal" ref={refOne}>
              <Modal show={show} onHide={() => setShow(false)}>
                <div
                  className=" "
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShow(false);
                    }}
                    icon={faClose}
                    size="2x"
                    color="red"
                  />
                </div>

                {orderSuccess === "Success" && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      style={{ marginLeft: "180px", marginTop: "20px" }}
                      width="100"
                      height="100"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#c8e6c9"
                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                      ></path>
                      <path
                        fill="#4caf50"
                        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                      ></path>
                    </svg>

                    <h5
                      style={{
                        textAlign: "center",
                        color: " rgb(83, 82, 82)",
                        paddingBottom: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      Your order was successfully submitted!
                    </h5>

                    <h6 className="orderNo" style={{ marginLeft: "120px" }}>
                      Your Order No :
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        {" " + submitOrderNo}
                      </span>
                    </h6>
                  </>
                )}
                {orderSuccess === "Failed" && (
                  <>
                    <Result
                      status="error"
                      title="Submission Failed"
                      subTitle="Please check and modify the following information before resubmitting."
                    >
                      <div className="desc">
                        <p>
                          <div
                            strong
                            style={{
                              fontSize: 16,
                            }}
                          >
                            The content you submitted has the following error:
                          </div>
                        </p>
                        {errorMessage}
                      </div>
                    </Result>
                  </>
                )}
              </Modal>
            </div>
          }
        </div>
        <CustomerFooter />
      </div>
    </>
  );
}
