import React from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <div className="container d-flex justify-content-center pb-5">
      <div className="row w-100 d-flex justify-content-center">
        <div className="col-12">
          <p className="pt-4">
            We wish to introduce ourselves in one of the leading pharmaceutical
            distributor at Ichalkaranji.The journey of "GREEN MEDICO LLP" was
            established by the group of some experienced leading retailers in
            the year of 2015 on 25 November. We started with all companies of
            pharma, cosmetics, generics, OTC product and surgical.
          </p>

          <p>
            Year by year we are growing with providing services like maximum
            product availability, on time delivery to customers.This is the
            destination for all your medical needs under one roof.
          </p>
        </div>
        <div className="col-12">
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
